<template>
  <div v-if="isLoad" fp-handler :class="['field-wrapper', {'success': isSuccess}]">
    <FontIcon :name="icon" />
    <h3>{{ title }}</h3>
    <p class="desc" v-html="desc"></p>
    <div class="bottom">
      <ProgressButton button-name="forgotPassword.label.openBrandId" :options="{brandId : brandId}" @click="action" />
    </div>
  </div>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';

export default {
  name: 'FpHandler',
  mixins: [fpErrorHandler],
  components: { ProgressButton, FontIcon },
  props: {
    structure: Specific,
  },
  data() {
    return {
      isLoad: false,
      success: false,
      model: {
        UserName: null,
        Token: null,
        Type: 'RESET_PASSWORD',
        Method: 'TOKEN'
      }
    };
  },
  computed: {
    brandId: state('env', 'gpBrand'),
    site: state('env', 'site'),
    siteInfo: state('env', 'siteInfo'),
    mobile: state('browser', 'mobile'),
    isSuccess() {
      return this.success;
    },
    icon() {
      return this.isSuccess ? 'check-circle-regular' : 'info-circle-line';
    },
    desc() {
      return this.isSuccess ? this.$t('forgotPassword.description.successDesc') : this.error.errorMsg;
    },
    title() {
      return this.isSuccess ? this.$t('forgotPassword.title.success') : this.$t('forgotPassword.title.failed');
    },
    isGGpass() {
      return !isExcludeGgpass(this.site);
    }
  },
  methods: {
    async getVerificationCode() {
      const result = await this.$services.ggpass.getVerification(this.model.Token, 'RESET_PASSWORD');
      if (result.error) {
        this.apiErrorHandler(result);
        this.errorMsg = this.error.errorMsg;
        return false;
      }
      const statusResult = this.statusHandler(result.status);

      return statusResult;
    },
    async sendRequest() {
      const result = await this.$services.ggpass.verifyCode(this.model);
      if (result.error) {
        this.success = false;
        this.apiErrorHandler(result);
        this.errorMsg = this.error.errorMsg;
        return;
      }

      const statusResult = this.statusHandler(result.status);
      if (statusResult.error) {
        this.success = false;
        this.$t(statusResult.key);
      } else {
        this.success = true;
      }
    },
    action() {
      location.href = 'ggnetworks://action?OnePass';
      // if (this.mobile) {
      //   location.href = 'ggnetworks://action?OnePass';
      // } else {
      //   location.href = 'ggnetworks://action?ggallianceId61';
      // }
    }
  },
  async mounted() {
    const { verificationToken, userName } = this.$route.query;
    this.model.Token = verificationToken || null;
    this.model.UserName = userName || null;
    if (!this.isGGpass) {
      await this.routeName('NotFound');
      return;
    }
    if (!this.model.Token && !this.model.UserName) {
      await this.routeName('NotFound');
      return;
    }

    const result = await this.getVerificationCode();
    if (!result) {
      this.isLoad = true;
      return;
    }

    await this.sendRequest();
    this.$nextTick(() => {
      this.isLoad = true;
    });

  },
  beforeDestroy() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-handler] {.tc();
  [font-icon] { .block(); .fs(60); .c(@c-red); }
  h3 { .regular(); .fs(28, 34); .m(24, 0, 12); }
  &.success {
    [font-icon] { .c(#1DB77D); }
  }
}

</style>